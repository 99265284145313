import React from 'react';

import { textContent, paperCategory, newsCategory, experienceCategory, dataArtAwardCategory } from '../../../types/data.global';

import MyPanel from '../../MyPanel';

import { Row, Col, Tabs } from 'antd';
import { TabsProps } from 'antd';
import { Avatar, List } from 'antd';
import _ from 'lodash';

const ExperienceAndContactSection: React.FC = () => { 
  // 4. experience
  const experienceTabElementList: TabsProps['items'] = []
  _.forEach(experienceCategory, (category) => {
    const experienceList = textContent.experiencePage.filter((experienceItem) => experienceItem.category.includes(category))
    let experienceElementList: JSX.Element[] = []
    if (category === experienceCategory.EXPERIENCE) {
      experienceElementList = experienceList.map(experienceInfo => {
        return (
          <div className='experience-unit-div' key={experienceInfo.title}>
            <div className='logo'>
              <div className='img-div' style={{border: 'none'}}>
                <img src={experienceInfo.institutionLogo} alt={experienceInfo.title} />
              </div>
            </div>
            <div className='text-info'>
              <div className='date'>{experienceInfo.timePeriod}</div>
              <div className='role'>
                <span>{experienceInfo.role}, </span>
                <span>with {experienceInfo.supervisor}</span>
              </div>
              <div className='institution'>{experienceInfo.institute}</div>
              <div className='location'>{experienceInfo.location}</div>
            </div>
          </div>
        )
      })
    } else if (category === experienceCategory.SERVICE) {
      experienceElementList = experienceList.map(experienceInfo => {
        // const serviceList = experienceInfo.venueList.map
        return (
          <div className='experience-unit-div'>
            <div className='text-info-service' style={{width: '100%'}}>
              <div className='role-type'>{experienceInfo.role}</div>
              <div>
                <List
                  itemLayout="horizontal"
                  dataSource={experienceInfo.venueList}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        // title={<a href="https://ant.design">{item}</a>}
                        description={item}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        )
      })
    } else if (category === experienceCategory.TEACHING) {
      experienceElementList = experienceList.map(experienceInfo => {
        return (
          <div className='experience-unit-div' style={{width: '100%'}}>
            <div className='text-info' style={{width: '100%'}}>
              <div className='role'>
                <span>{experienceInfo.role}</span>
              </div>
              <div className='institution'>{experienceInfo.title}</div>
            </div>
          </div>
        )
      })
    } else {
      experienceElementList = experienceList.map(experienceInfo => {
        return (
          <div className='experience-unit-div'>
            <div className='rd-logo'>
              <div className='img-div'>
                <img src={experienceInfo.teaser} alt={experienceInfo.title} />
              </div>
            </div>
            <div className='text-info'>
              <div className='role'>
                <span>{experienceInfo.title}</span>
              </div>
              <div className='institution'>{experienceInfo.introduction}</div>
            </div>
          </div>
        )
      })
    }
    experienceTabElementList.push({
      key: category,
      label: category,
      children: experienceElementList,
    })
  })

  const onChange = (key: string) => {
    console.log(key)
  }

  return (
    <div className='page-div' style={{width: '100%'}}>
      <Row justify="space-between" style={{width: '100%'}} className='page-div'>
        <Col xs={24} sm={24} md={15} lg={15} className='left-div'>
          <MyPanel viewTitle="EXPERIENCE, SERVICE, R&Ds" width={''} height={'auto'} panelHeaderHeight={72}>
            <div style={{padding: 16}} id='experiencePage'>
              <Tabs defaultActiveKey={newsCategory.ALL} items={experienceTabElementList} onChange={onChange} />
            </div>
          </MyPanel>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} className='left-div'>
          <MyPanel viewTitle="FOR MORE INFORMATION" width={''} height={'auto'} panelHeaderHeight={72}>
            <div className='contact-div' id='contactPage'>
              <List
                itemLayout="horizontal"
                dataSource={textContent.contactPage}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.platformLogo} shape="square" />}
                      title={item.platform}
                      description={<a href={item.link}>{item.content}</a>}
                    />
                  </List.Item>
                )}
              />
            </div>
          </MyPanel>
        </Col>
      </Row>
    </div>
  )
};

export default ExperienceAndContactSection;