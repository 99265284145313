import React from 'react';

import './index.scss';
import MyPanel from '../../MyPanel';
import { Row, Col } from 'antd';
// import VennDiagram from './VennDiagram';

import maskUrl from '../../../assets/images/9-mask/mask5.svg';
import rolesImage from '../../../assets/images/1-about/roles.jpg';
import identitySvg from './identity.svg';

interface IntroductionSectionProps {
  textContent: any;
}

const IntroductionSection: React.FC<IntroductionSectionProps> = (props) => {
  const { textContent } = props;

  return (
    <Row justify="space-between" style={{width: '100%'}} className='page-div' id='aboutPage'>
      <Col xs={24} sm={24} md={24} lg={18} className='left-div'>
        <MyPanel viewTitle="HELLO, " width={''} height={'auto'} panelHeaderHeight={72}>
          <div style={{padding: 16}}>
            <div className='plain-text-div'>{textContent.aboutPage.introduction}</div>
          </div>
        </MyPanel>
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} className='left-div'>
        <div className='profile-image-div'>
            <div className='image-div' style={{padding: 16, marginTop: 20}}>
              <img src={textContent.aboutPage.profileImageUrl} className='author-image-class' alt='yifang'
                style={{
                  // WebkitMaskBoxImage: `url(${maskUrl})`,
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className='short-bio-div'>
              {/* <a href={cvUrl}>CV</a> | <a href='#contactPage'>Contact Information</a> */}
              { (textContent.contactPageSM.map((item: any) => {
                return (
                  <div className='contact-logo-div'>
                    <a href={item.link}>
                      <img src={item.platformLogo} alt={item.platform} />
                    </a>
                  </div>
                )
              }))}
              {/* <List
                itemLayout="horizontal"
                dataSource={textContent.contactPage}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.platformLogo} shape="square" />}
                      // title={item.platform}
                      // description={<a href={item.link}>{item.content}</a>}
                    />
                  </List.Item>
                )}
              /> */}
            </div>
            <div className='identify-div'>
              <div className='identity-row row-1'>
                <div className='identity-unit cs'>Computer Scientist</div>
                <div className='identity-unit da'>Data Artist</div>
              </div>
              <div className='identity-row'>
                <div className='identity-unit css'>Computational Social Scientist</div>
              </div>
            </div>
        </div>
      </Col>
      {/* <Col xs={24} sm={24} md={24} lg={4} className='role-div'>
        <div className='roles-div'>
          <img className='roles-image' src={rolesImage} alt='roles' />
        </div>
      </Col> */}
    </Row>
)};

export default IntroductionSection;